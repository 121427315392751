* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* MARK: HIDES SCROLL BAR, BUT STILL ALLOWS SCROLLING*/
html {
    overflow: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* Hides scrollbar in IE 10+*/
    scrollbar-width: none;  /* Hides scrollbar in Firefox */
    font-size: 1rem;
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 0;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

div.root-content {
    display: flex;
    flex-direction: column;
}

/* NavigationBar */
a.nav-button {
    display: flex;
    align-self: center;
    justify-content: center;
    text-decoration: none;
    width: 6rem;
    color: #2180CE;
}

/*  */

