div.home-container {
    display: grid;
    min-height: 100vh;
    grid-template-columns: 1fr;
    overflow: hidden;
}

div.call-action {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.call-action h2 {
    text-align: center;
    font-size: 3rem;
}

div.call-action Button {
    margin-top: 2rem;
    min-height: 4rem;
    min-width: 12rem;
    font-size: 1.5rem;
    border-radius: 1rem;
}

/* Media Query */
@media (max-width:600px) {
    div.home-container {
        grid-template-columns: 1fr;
    }


}


