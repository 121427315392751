* {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* MARK: HIDES SCROLL BAR, BUT STILL ALLOWS SCROLLING*/
html {
    overflow: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* Hides scrollbar in IE 10+*/
    scrollbar-width: none;  /* Hides scrollbar in Firefox */
    font-size: 1rem;
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 0;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

div.root-content {
    display: flex;
    flex-direction: column;
}

/* NavigationBar */
a.nav-button {
    display: flex;
    align-self: center;
    justify-content: center;
    text-decoration: none;
    width: 6rem;
    color: #2180CE;
}

/*  */


div.home-container {
    display: grid;
    min-height: 100vh;
    grid-template-columns: 1fr;
    overflow: hidden;
}

div.call-action {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.call-action h2 {
    text-align: center;
    font-size: 3rem;
}

div.call-action Button {
    margin-top: 2rem;
    min-height: 4rem;
    min-width: 12rem;
    font-size: 1.5rem;
    border-radius: 1rem;
}

/* Media Query */
@media (max-width:600px) {
    div.home-container {
        grid-template-columns: 1fr;
    }


}




